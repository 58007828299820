import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductsPageDropdownLink } from './DropdownLink';
import { productsPagePath, ProductsPageType } from './Navigation';
import { useLibraryContext } from '../../../context/LibraryContext';
import { AgeCategoryType, parseAgeCategoryType } from '../../../domain/ageCategory';
import { LoanFormatType, parseLoanFormatType } from '../../../utils/domain/loanFormat';
import { isEmpty, isPresent } from '../../../utils/objectChecks';
import { ButtonWithPopover } from '../../floater/popover/ElementWithPopover';
import { Icon } from '../../Icon';

const TOGGLE_HTML_ID = 'languageDropdown';

type LanguageDropdownProps = {
    readonly loanFormat: LoanFormatType;
    readonly selectedAgeCategory: AgeCategoryType;
    readonly pageType: ProductsPageType;
};

export const LanguageDropdown: FC<LanguageDropdownProps> = ({ loanFormat, selectedAgeCategory, pageType }) => {
    const { t } = useTranslation();
    const { browsingLanguages } = useLibraryContext();

    if (isEmpty(browsingLanguages.mainLanguages) && isEmpty(browsingLanguages.otherLanguages)) return null;

    return (
        <ButtonWithPopover
            popover={<Popover loanFormat={loanFormat} ageCategory={selectedAgeCategory} pageType={pageType} />}
            className="button theme-rounded"
            role="button"
            aria-labelledby={TOGGLE_HTML_ID}
            aria-haspopup="menu"
        >
            <Icon icon="subject" /> {t('languageBrowsing.labels.languages')}
        </ButtonWithPopover>
    );
};

type PopoverProps = {
    readonly loanFormat: LoanFormatType;
    readonly ageCategory: AgeCategoryType;
    readonly pageType: ProductsPageType;
};

const Popover: FC<PopoverProps> = ({ loanFormat, ageCategory, pageType }) => {
    const { browsingLanguages } = useLibraryContext();

    const { t } = useTranslation();

    return (
        <div className="component popover-list" role="menu" aria-labelledby={TOGGLE_HTML_ID}>
            <div className="header">{t('languageBrowsing.browseLanguages')}</div>
            <ul>
                {browsingLanguages.mainLanguages?.map(({ genreId, slug }) => (
                    <li className="has-icon-right" key={genreId}>
                        <ProductsPageDropdownLink
                            ageCategory={ageCategory}
                            pageType={pageType}
                            loanFormat={loanFormat}
                            languageSlug={slug}
                        >
                            {t(`filters:genre.${genreId}`, slug)}
                            <Icon icon="star" className="libcolor-text" />
                        </ProductsPageDropdownLink>
                    </li>
                ))}
                {browsingLanguages.otherLanguages
                    .find(item => parseLoanFormatType(item.name) === loanFormat)
                    ?.ageCategories?.find(item => parseAgeCategoryType(item.name) === ageCategory)
                    ?.languages?.map(({ genreId, slug }, index) => (
                        <li
                            className={
                                isPresent(browsingLanguages.mainLanguages) && index === 0 ? 'divider-group' : undefined
                            }
                            key={genreId}
                        >
                            <a
                                href={productsPagePath({
                                    loanFormat,
                                    languageSlug: slug,
                                    ageCategory
                                })}
                            >
                                {t(`filters:genre.${genreId}`, slug)}
                            </a>
                        </li>
                    ))}
            </ul>
        </div>
    );
};
