import compact from 'lodash/compact';
import uniqBy from 'lodash/uniqBy';

import { ApiContextLibrary } from '../../api/apiLibraryContext';
import { AgeCategoryName, LoanFormatName } from '../../api/apiLibraryContextEnums';
import { AgeCategoryType, parseAgeCategoryType } from '../../domain/ageCategory';
import { LoanFormatType, parseLoanFormatType } from '../domain/loanFormat';

const isDevelopment = process.env.NODE_ENV === 'development';

export const LANGUAGE_GENRE_KEY = isDevelopment ? '3268' : '2288';
const ENGLISH_GENRE_ID = isDevelopment ? '1155' : '2498';

const MAIN_LANGUAGE_MAP: ReadonlyArray<BrowsingLanguage> = [
    { languageId: 'en', slug: 'english', genreId: ENGLISH_GENRE_ID }
];

type BrowsingLanguage = {
    readonly genreId: string;
    readonly languageId?: string;
    readonly slug: string;
    readonly active?: boolean;
};

export type BrowsingLanguages = {
    readonly mainLanguages: ReadonlyArray<BrowsingLanguage>;
    readonly otherLanguages: ReadonlyArray<{
        readonly name: LoanFormatName;
        readonly ageCategories: ReadonlyArray<{
            readonly name: AgeCategoryName;
            readonly languages: ReadonlyArray<BrowsingLanguage>;
        }>;
    }>;
};

export const getBrowsingLanguages = (
    value: ApiContextLibrary | undefined,
    languageSlug: string | undefined
): BrowsingLanguages => {
    const mainLanguages = compact(
        uniqBy(
            value?.navigation?.defaultCollection.loanFormats?.map(({ language }) => {
                const languageMapped = MAIN_LANGUAGE_MAP.find(item => item.languageId === language);
                return languageMapped
                    ? { ...languageMapped, active: languageMapped?.slug === languageSlug }
                    : undefined;
            }),
            'genreId'
        )
    );

    const otherLanguages =
        value?.navigation?.defaultCollection.loanFormats?.map(({ name, ageCategories }) => ({
            name,
            ageCategories: ageCategories.map(ageCategory => ({
                name: ageCategory.name,
                languages:
                    ageCategory.genresPage?.genres
                        ?.find(({ genreId }) => genreId === LANGUAGE_GENRE_KEY)
                        ?.subGenres.map(({ genreId, genreName }) => ({
                            genreId,
                            slug: genreName.toLocaleLowerCase(),
                            active: genreName.toLocaleLowerCase() === languageSlug
                        })) ?? []
            }))
        })) ?? [];

    return { mainLanguages, otherLanguages };
};

export const getActiveLanguage = (browsingLanguages: BrowsingLanguages): BrowsingLanguage | undefined => {
    const availableOtherLanguages = getOtherLanguages(browsingLanguages);
    return [...browsingLanguages.mainLanguages, ...availableOtherLanguages].find(({ active }) => active);
};

export const getAvailableOtherLanguages = (
    browsingLanguages: BrowsingLanguages,
    loanFormat: LoanFormatType | undefined,
    ageCategory: AgeCategoryType
) => {
    return (
        browsingLanguages.otherLanguages
            .find(item => parseLoanFormatType(item.name) === loanFormat)
            ?.ageCategories?.find(item => parseAgeCategoryType(item.name) === ageCategory)?.languages ?? []
    );
};

const getOtherLanguages = (browsingLanguages: BrowsingLanguages) => {
    let otherlanguages: Array<BrowsingLanguage> = [];

    browsingLanguages.otherLanguages.forEach(loanFormat =>
        loanFormat.ageCategories.forEach(ageCategory =>
            ageCategory.languages.forEach(language => otherlanguages.push(language))
        )
    );

    return otherlanguages;
};
