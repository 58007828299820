import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useCallback, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { useLoginMutation } from '../../api/authentication/useLoginMutation';
import { ErrorMessage } from '../../components/ErrorMessage';
import { Icon } from '../../components/Icon';
import { ValidatedField } from '../../components/ValidatedField';
import { usePatronInfo, useUserRole } from '../../context/AuthenticationContext';
import { useCredits } from '../../context/CreditsContext';
import { useLibraryContext } from '../../context/LibraryContext';
import { useRedirect } from '../../hooks/events/useRedirect';
import { useForgotPasswordLink } from '../../hooks/getters/useForgotPasswordLink';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { useTextInput } from '../../hooks/utils/useFormInput';
import { customize } from '../../utils/localizations/customize';
import { isEmpty } from '../../utils/objectChecks';
import { HOME_PATH, REGISTER_PATH } from '../../utils/routes/paths';
import { validateNotEmpty } from '../../utils/validation';
import { AppStoreLinks } from '../home/my-loans/access/AppStoreLinks';

export function InternalLogin() {
    const { t } = useTranslation();
    const router = useRouter();
    const redirectTo = useRedirect();
    const { redirectTo: redirectQuery } = router.query;

    const { reset: _barcodeInputReset, ...barcodeInput } = useTextInput('', { validate: validateNotEmpty });
    const { reset: _pinInputReset, ...pinInput } = useTextInput('', { validate: validateNotEmpty });

    const { mutate: doLogin, error, isPending } = useLoginMutation();
    const { isGuestUser, isFullUser, isTempUser } = useUserRole();
    const { credits } = useCredits();

    // A successful login updates the patron info in the AuthenticationContext which fully re-render this form and
    // states get lost. So we use the user role to determine if the login was successful.
    // - isGuestUser: user is not logged in
    // - isFullUser: user is logged in
    // - isTempUser: user is logged in, but has not yet registered
    const isSuccess = isFullUser || isTempUser;

    // Use username for initial barcode value, because form data gets lost on successful login
    const { username } = usePatronInfo();

    const { siteId, language } = useSiteParams();

    const { siteName: libraryName } = useLibraryContext();

    const submittable = !isPending && !isSuccess;

    useEffect(() => {
        const isNoneTempUserWithoutCredits = !isTempUser && isEmpty(credits);

        if (isGuestUser || isNoneTempUserWithoutCredits) return;

        const redirect = isTempUser
            ? REGISTER_PATH
            : isFullUser && typeof redirectQuery === 'string'
              ? redirectQuery
              : HOME_PATH;

        void redirectTo(redirect);
    }, [credits, isFullUser, isGuestUser, isTempUser, redirectQuery, redirectTo]);

    const onSubmit = useCallback(
        async (data: { barcode: string; password: string }) => {
            if (!submittable) return;

            doLogin({
                username: data.barcode,
                password: data.password,
                siteId,
                language
            });
        },
        [doLogin, siteId, language, submittable]
    );

    const loginHelpText = t('login.help_text', { defaultValue: null });
    const joinLink = t('login.join_link', { defaultValue: null });

    return (
        <main className="container size-narrow">
            <div className="library-login-intro">
                <h1>{t('login.title', { libraryName })}</h1>
                <p>{t('login.subtitle')}</p>
            </div>
            <Form
                onSubmit={onSubmit}
                initialValues={{ barcode: username, password: isSuccess ? '****' : '' }}
                validate={values => {
                    return {
                        barcode: !values.barcode ? t('defaults.form.validation.required') : undefined,
                        password: !values.password ? t('defaults.form.validation.required') : undefined
                    };
                }}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        {error ? (
                            <ErrorMessage message={error.response?.data?.messages?.[0] ?? t('login.error_text')} />
                        ) : null}
                        <ValidatedField
                            type="text"
                            name="barcode"
                            disabled={isSuccess}
                            label={t('login.barcode_label')}
                            id={barcodeInput.inputId}
                            helpText={t('login.barcode_help_text')}
                        />
                        <ValidatedField
                            name="password"
                            type="password"
                            disabled={isSuccess}
                            label={t('login.password_label')}
                            id={pinInput.inputId}
                            helpText={t('login.password_help_text')}
                        />
                        <button
                            className="button-positive size-large size-fullwidth signin-button"
                            type="submit"
                            disabled={!submittable || isSuccess}
                        >
                            {submittable ? t('login.log_in') : t('login.logging_in')}
                        </button>
                    </form>
                )}
            />
            <ForgotPasswordLink />
            {loginHelpText ? (
                <p className="find-a-library">
                    <Icon icon="info-filled" className="libcolor-text" />
                    {customize(loginHelpText)}
                </p>
            ) : null}
            {joinLink ? (
                <Link href={joinLink} className="button-neutral size-large size-fullwidth joinnow-button">
                    {t('login.join_now')}
                </Link>
            ) : null}
            <hr />
            <p className="app-notice">{t('login.download_app')}</p>
            <div className="app-links">
                <AppStoreLinks color="Black" />
            </div>
        </main>
    );
}

const ForgotPasswordLink: FC = () => {
    const { t } = useTranslation();
    const resetUrl = useForgotPasswordLink();

    if (!resetUrl) return null;

    return (
        <div className="forgotpassword-link">
            <Link href={resetUrl} style={{ textDecoration: 'underline' }}>
                {t('login.password_link_text')}
            </Link>
        </div>
    );
};
