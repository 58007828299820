import { AgeCategoryName, LoanFormatName } from './apiLibraryContextEnums';
import { GenreId } from './genres';
import { PageSlotsResponse } from './productGroup';
import { AgeCategoryType } from '../domain/ageCategory';
import { ApiClient } from '../setup/axios';
import { LoanFormatType } from '../utils/domain/loanFormat';

export const ERROR_UNKNOWN_SITE = 'err.context.friendlyLink_not_found';
export const ERROR_INACTIVE_SITE = 'err.context.site_inactive';

export type ApiContextLibraryThemeDesign = {
    readonly pp_bar_color: string;
};

export type ApiContextLibraryTheme = {
    readonly design: ApiContextLibraryThemeDesign;
    readonly logoUrl: string;
};

export type ApiContextLoanPeriods = {
    readonly [L in LoanFormatType]?: number;
};

export type LoginType = 'SAML' | 'EZPROXY' | 'OIDC' | 'INTERNAL';

export type ApiContextLogin = {
    readonly ssoServerUrl?: string;
    readonly userManagementType: LoginType;
    readonly supportsInternalPasswordReset: boolean;
};

export enum LanguageSelectionDisplay {
    NONE = 'NONE',
    STANDARD = 'STANDARD',
    PROMINENT = 'PROMINENT'
}

type NetworkSites = ReadonlyArray<{
    readonly name: string;
    readonly link: string;
}>;

export type ApiContextLibrary = ApiContextLogin &
    ApiContextLibraryTheme & {
        readonly loanPeriods: ApiContextLoanPeriods;
        readonly timezone: string;
        readonly siteId: string;
        readonly siteName: string;
        readonly friendlyLinkName: string;
        readonly logoUrl: string;
        readonly homeLink: string;
        readonly languageSelectionDisplay: LanguageSelectionDisplay;
        readonly navigation?: ApiContextNavigation;
        readonly networkSite: boolean;
        readonly networkSites?: NetworkSites;
    };

export type ApiContextNavigationFeaturedPage = {
    readonly slots?: PageSlotsResponse;
};

export type ApiContextNavigationCategoriesPage = {
    readonly slots?: PageSlotsResponse;
};

type ApiContextNavigationStaticPage = {
    readonly slots?: PageSlotsResponse;
};

export type ApiContextNavigationGenre = {
    readonly genreId: GenreId;
    readonly genreName: string;
    readonly subGenres: ReadonlyArray<ApiContextNavigationSubGenre>;
    readonly productCount: number;
    readonly topGenre: boolean;
    readonly imageUrl?: string;
    readonly position?: number;
};

export type ApiContextNavigationSubGenre = {
    readonly genreId: GenreId;
    readonly genreName: string;
    readonly productCount: number;
};

export type ApiContextNavigationGenrePage = {
    readonly genres?: ReadonlyArray<ApiContextNavigationGenre>;
};

type ApiContextNavigationHomePage = {
    readonly featuredPage: ApiContextNavigationFeaturedPage;
};

type ApiContextEPressPage = {
    readonly totalCount: number;
    readonly productGroupId: number;
};

export type ApiContextNavigationAgeCategory = {
    readonly name: AgeCategoryName;
    readonly featuredPage?: ApiContextNavigationFeaturedPage;
    readonly categoriesPage?: ApiContextNavigationCategoriesPage;
    readonly staticPage?: ApiContextNavigationStaticPage;
    readonly genresPage?: ApiContextNavigationGenrePage;
    readonly allEMagazinePage?: ApiContextEPressPage;
    readonly allENewsPage?: ApiContextEPressPage;
};

export type ApiContextNavigationLoanFormats = {
    readonly name: LoanFormatName;
    readonly ageCategories: ReadonlyArray<ApiContextNavigationAgeCategory>;
    readonly language: string;
};

export interface ApiContextNavigationCollection<T extends string> {
    readonly name: T;
    readonly home: ApiContextNavigationHomePage;
    readonly loanFormats?: ReadonlyArray<ApiContextNavigationLoanFormats>;
}

export type ApiContextNavigation = {
    readonly defaultCollection: ApiContextNavigationCollection<'default'>;
};

export async function fetchLibraryContext(client: ApiClient, siteAlias: string): Promise<ApiContextLibrary> {
    const res = await client.get<ApiContextLibrary>(`/api/v3/context/${siteAlias}`);
    return res.data;
}

export const mapAgeCategoryTypeToAgeCategoryName = (ageCategoryType: AgeCategoryType): AgeCategoryName => {
    switch (ageCategoryType) {
        case AgeCategoryType.allAges:
            return AgeCategoryName.allAges;
        case AgeCategoryType.adult:
            return AgeCategoryName.adult;
        case AgeCategoryType.youngAdult:
            return AgeCategoryName.youngAdult;
        case AgeCategoryType.children:
            return AgeCategoryName.children;
    }
};
