import pick from 'lodash/pick';

import {
    ApiContextNavigation,
    ApiContextNavigationCategoriesPage,
    ApiContextNavigationFeaturedPage,
    ApiContextNavigationGenrePage
} from '../../api/apiLibraryContext';
import { ProductsPageType } from '../../components/layout/header/Navigation';
import { AgeCategoryType, parseAgeCategoryType } from '../../domain/ageCategory';
import { mapFilterNameUserFriendly, PRODUCT_LIST_QUERY_PARAMS } from '../../hooks/getters/useProductListParams';
import { LoanFormatType, parseLoanFormatType } from '../domain/loanFormat';

export function extractBasePath(url: string | undefined) {
    if (!url) return '';

    const index = url.indexOf('?');

    return index === -1 ? url : url.substring(0, index);
}

export function extractQueryString(url: string | undefined) {
    if (!url) return '';

    const index = url.indexOf('?');

    return index === -1 ? '' : url.substring(index + 1);
}

export type QueryParam = string | Array<string | null> | null | undefined;

type Query = {
    [key: string]: QueryParam;
};

// eslint-disable-next-line max-len
// FilterCategoryDto: https://gitlab.com/bolinda/borrowbox/patron-point/-/blob/master/src/main/java/com/borrowbox/patronpoint/webapp/model/request/FilterCategoryDto.java
const FILTER_CATEGORY_QUERY_PARAMS = [
    'loanFormat',
    'ageCategory',
    'ageGroup',
    'fiction',
    'genre',
    'languages',
    'pages',
    'duration',
    'productionFormat',
    'releaseDate',
    'newlyAdded',
    'unknown'
];

export function filterNonProductListQueryParams(query: Query): Query {
    return pick(query, [
        ...PRODUCT_LIST_QUERY_PARAMS,
        ...FILTER_CATEGORY_QUERY_PARAMS.map(item => mapFilterNameUserFriendly(item))
    ]);
}

type FindPageOptions = {
    readonly ageCategory: AgeCategoryType;
    readonly loanFormat: LoanFormatType;
    readonly navigation: ApiContextNavigation;
};

type PageType<T extends ProductsPageType> = T extends ProductsPageType.Featured
    ? ApiContextNavigationFeaturedPage
    : T extends ProductsPageType.Explore
      ? ApiContextNavigationCategoriesPage
      : T extends ProductsPageType.Genres
        ? ApiContextNavigationGenrePage
        : never;

export const findPage = <T extends Exclude<ProductsPageType, ProductsPageType.All>>(
    pageType: T,
    { ageCategory, loanFormat, navigation }: FindPageOptions
): PageType<T> | null => {
    const collectionNav = navigation.defaultCollection;
    const loanFormatNav = collectionNav.loanFormats?.find(lf => parseLoanFormatType(lf.name) === loanFormat);
    const ageCategoryNav = loanFormatNav?.ageCategories.find(ac => parseAgeCategoryType(ac.name) === ageCategory);

    switch (pageType) {
        case ProductsPageType.Featured:
            return (ageCategoryNav?.featuredPage as PageType<T>) ?? null;
        case ProductsPageType.Explore:
            return (ageCategoryNav?.categoriesPage as PageType<T>) ?? null;
        case ProductsPageType.Genres:
            return (ageCategoryNav?.genresPage as PageType<T>) ?? null;
    }

    throw new Error(`Unsupported page type ${pageType}`);
};
