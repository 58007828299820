import Link from 'next/link';
import {
    ComponentProps,
    Dispatch,
    FC,
    forwardRef,
    MouseEventHandler,
    PropsWithChildren,
    SetStateAction,
    useEffect,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { useLogoutMutation } from '../../../api/authentication/useLogoutMutation';
import { usePatronInfo } from '../../../context/AuthenticationContext';
import { useCredits } from '../../../context/CreditsContext';
import { usePreventDefault } from '../../../hooks/utils/usePreventDefault';
import { loanFormatToGildClass, sortByDefaultLoanFormatOrder } from '../../../utils/domain/loanFormat';
import { i18nLoanFormatMap } from '../../../utils/localizations/i18nextMaps';
import { HELP_ROUTE_PATH, MY_ACCOUNT_PATH, MY_LOANS_PATH } from '../../../utils/routes/paths';
import { LinkWithPopover } from '../../floater/popover/ElementWithPopover';
import { getFilled, Icon } from '../../Icon';

const MOBILE_BREAK_WIDTH = 1420; // `1420` px is the CSS mobile break width

type AccountStatusProps = {
    readonly setPopoverOpen?: Dispatch<SetStateAction<boolean>>;
};

export const AccountStatus: FC<AccountStatusProps> = ({ setPopoverOpen }) => {
    return (
        <div className="account-status">
            <div className="left">
                <AccountCredits />
            </div>
            <AccountStatusDropdown setPopoverOpen={setPopoverOpen} />
        </div>
    );
};

const AccountStatusDropdown: FC<AccountStatusProps> = ({ setPopoverOpen }) => {
    const { t } = useTranslation();
    const [renderCreditItem, setRenderCreditItem] = useState(false);

    const { username, displayName } = usePatronInfo();
    const { mutate: logout } = useLogoutMutation();

    const logoutPreventDefault = usePreventDefault(logout);

    const handleResize = () => setRenderCreditItem(window.innerWidth <= MOBILE_BREAK_WIDTH);

    useEffect(handleResize, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    });

    const popover = (
        <div className="component popover-list">
            <ul>
                {renderCreditItem ? <AccountCreditsListItem /> : null}
                <AccountStatusDropdownOption href={MY_ACCOUNT_PATH} listClassName="multi-line">
                    <span>{t('header.nav.my_account')}</span>
                    <br />
                    <span className="subinfo">{displayName ?? username}</span>
                </AccountStatusDropdownOption>
                <AccountStatusDropdownOption href={HELP_ROUTE_PATH}>
                    <span>{t('header.help')}</span>
                </AccountStatusDropdownOption>
                <AccountStatusDropdownOption
                    listClassName="sign-out-item"
                    href="#"
                    className="no-libcolor-text-on-link-hover"
                    handleClick={logoutPreventDefault}
                    role="button"
                >
                    {t('header.sign_out')}
                </AccountStatusDropdownOption>
            </ul>
        </div>
    );

    return (
        <div className="my-account">
            <LinkWithPopover
                popover={popover}
                placement="bottom-end"
                className="libcolor-text"
                role="button"
                aria-label={t('header.account_status')}
                aria-haspopup="menu"
                setPopoverOpen={setPopoverOpen}
            >
                <Icon icon="account-circle" />
                <Icon icon="caret-down" />
            </LinkWithPopover>
        </div>
    );
};

const AccountCredits: FC = () => {
    const { credits } = useCredits();
    const { t } = useTranslation();

    return (
        <div className="credits-available">
            <Link href={MY_LOANS_PATH} legacyBehavior>
                <a>
                    {sortByDefaultLoanFormatOrder(credits).map((credit, index) => (
                        <div
                            key={index}
                            className="credit-type"
                            aria-label={t('header.credits', {
                                loanFormat: credit.loanFormat,
                                amount: credit.amount
                            })}
                        >
                            <Icon
                                icon={getFilled(loanFormatToGildClass(credit.loanFormat))}
                                className="libcolor-text"
                            />
                            {credit.amount}
                        </div>
                    ))}
                </a>
            </Link>
        </div>
    );
};

const AccountCreditsListItem = forwardRef<HTMLAnchorElement>(({ ...props }, ref) => {
    const { t } = useTranslation();
    const { credits } = useCredits();

    return (
        <li className="credits-available-item credits-available">
            <Link href={MY_LOANS_PATH} legacyBehavior>
                <a {...props} ref={ref}>
                    {credits.map((credit, index) => (
                        <div
                            key={index}
                            className="credit-type"
                            aria-label={t('header.credits', {
                                loanFormat: t(`defaults.singular.${i18nLoanFormatMap[credit.loanFormat]}`, {
                                    context: 'short'
                                }),
                                amount: credit.amount
                            })}
                        >
                            <Icon
                                icon={getFilled(loanFormatToGildClass(credit.loanFormat))}
                                className="libcolor-text"
                            />
                            {credit.amount}
                        </div>
                    ))}
                </a>
            </Link>
        </li>
    );
});

type AccountStatusDropdownOptionProps = PropsWithChildren<{
    readonly listClassName?: string;
    readonly handleClick?: MouseEventHandler;
}> &
    ComponentProps<'a'>;

const AccountStatusDropdownOption = forwardRef<HTMLAnchorElement, AccountStatusDropdownOptionProps>(
    ({ listClassName, handleClick, children, ...props }, ref) => {
        return (
            <li className={listClassName}>
                <a {...props} ref={ref} onClick={event => handleClick?.(event)}>
                    {children}
                </a>
            </li>
        );
    }
);
