import merge from 'lodash/merge';
import { useParams } from 'next/navigation';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';

import {
    ApiContextLibrary,
    ApiContextLibraryTheme,
    ApiContextLoanPeriods,
    ApiContextLogin,
    LanguageSelectionDisplay
} from '../api/apiLibraryContext';
import { AgeCategoryType, parseAgeCategoryType } from '../domain/ageCategory';
import { DEFAULT_COLLECTION_SLUG } from '../utils/constants';
import { parseLoanFormatType } from '../utils/domain/loanFormat';
import { filterSiteContextByContentLanguage } from '../utils/domain/siteContext';
import { BrowsingLanguages, getActiveLanguage, getBrowsingLanguages } from '../utils/navigation/languageBrowsing';
import { INavigationState, parseNavigationState } from '../utils/navigation/parseNavigationState';
import { isPresent } from '../utils/objectChecks';

export type LibraryContextValue = {
    readonly siteContext: ApiContextLibrary | undefined;
    readonly theme: ApiContextLibraryTheme | undefined;
    readonly login: ApiContextLogin | undefined;
    readonly loanPeriods: ApiContextLoanPeriods | undefined;
    readonly timezone: string | undefined;
    readonly siteId: string | undefined;
    readonly homeLink: string | undefined;
    readonly siteName: string | undefined;
    readonly libraryError: boolean;
    readonly languageSelectionDisplay: LanguageSelectionDisplay;
    readonly navigation: INavigationState;
    readonly browsingLanguages: BrowsingLanguages;
    readonly isLanguageBrowsing: boolean;
};

const defaultNavigationState: INavigationState = {
    collections: {
        [DEFAULT_COLLECTION_SLUG]: {
            name: 'Home',
            loanFormats: {}
        }
    }
};

const initialValue: LibraryContextValue = {
    siteContext: undefined,
    theme: undefined,
    login: undefined,
    loanPeriods: undefined,
    timezone: undefined,
    siteId: undefined,
    homeLink: undefined,
    siteName: undefined,
    libraryError: false,
    languageSelectionDisplay: LanguageSelectionDisplay.NONE,
    navigation: defaultNavigationState,
    browsingLanguages: { mainLanguages: [], otherLanguages: [] },
    isLanguageBrowsing: false
};

function reduce(
    state: LibraryContextValue,
    value: ApiContextLibrary | undefined,
    languageSlug: string | undefined,
    error: boolean
): LibraryContextValue {
    const browsingLanguages = getBrowsingLanguages(value, languageSlug);
    const activeLanguage = getActiveLanguage(browsingLanguages);
    const isLanguageBrowsing = isPresent(activeLanguage);

    const filteredSiteContext = value
        ? filterSiteContextByContentLanguage(value, activeLanguage?.languageId)
        : undefined;
    const navigationState = filteredSiteContext?.navigation
        ? parseNavigationState(filteredSiteContext.navigation)
        : undefined;

    const { loanPeriods, timezone, siteId, homeLink, siteName, languageSelectionDisplay } = value ?? state;

    const theme = value
        ? {
              design: value.design,
              logoUrl: value.logoUrl
          }
        : state.theme;

    const login = value
        ? {
              ssoServerUrl: value.ssoServerUrl,
              userManagementType: value.userManagementType,
              supportsInternalPasswordReset: value.supportsInternalPasswordReset
          }
        : state.login;

    return {
        siteContext: value,
        theme,
        login,
        loanPeriods,
        timezone,
        siteId,
        homeLink,
        siteName,
        languageSelectionDisplay,
        navigation: merge({}, state.navigation, navigationState),
        libraryError: error,
        browsingLanguages,
        isLanguageBrowsing
    };
}

const LibraryContext = createContext<LibraryContextValue>(initialValue);
LibraryContext.displayName = 'LibraryContext';

export function useLibraryContext(): LibraryContextValue {
    return useContext(LibraryContext);
}

type LibraryContextProviderProps = PropsWithChildren<{
    readonly value?: ApiContextLibrary;
    readonly error: boolean;
}>;

export const LibraryContextProvider: FC<LibraryContextProviderProps> = ({ children, value, error }) => {
    const { language: languageRaw, loanFormatPath: loanFormatRaw, ageCategoryPath: ageCategoryRaw } = useParams();

    const language = typeof languageRaw === 'string' ? languageRaw : undefined;
    const loanFormat = typeof loanFormatRaw === 'string' ? parseLoanFormatType(loanFormatRaw) : undefined;
    const ageCategory =
        typeof ageCategoryRaw === 'string' ? parseAgeCategoryType(ageCategoryRaw) : AgeCategoryType.allAges;

    const initialRender = useRef(true);
    const [apiContextLibrary, setApiContextLibrary] = useState(value);

    const [libraryContext, setLibraryContext] = useState<LibraryContextValue>(() =>
        reduce(initialValue, value, language, error)
    );

    useEffect(() => {
        if (value) setApiContextLibrary(value);
    }, [value]);

    useEffect(() => {
        if (initialRender.current) {
            // do not update library context on initial render, because initial value is already set, and we don't want
            // to trigger an unnecessary rerender when initialized
            initialRender.current = false;
        } else {
            // update library context on subsequent renders
            const siteContext = value ?? apiContextLibrary;
            if (siteContext) {
                setLibraryContext(prevState => reduce(prevState, siteContext, language, error));
            }
        }
    }, [value, error, apiContextLibrary, language, loanFormat, ageCategory]);

    return <LibraryContext.Provider value={libraryContext}>{children}</LibraryContext.Provider>;
};
