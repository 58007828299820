import classNames from 'classnames';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import Link from 'next/link';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AgeCategoryDropdown } from './AgeCategoryDropdown';
import { LanguageDropdown } from './LanguageDropdown';
import { IProductsPageRef, PAGE_TYPE_ORDER, productsPagePath, ProductsPageType } from './Navigation';
import { useLibraryContext } from '../../../context/LibraryContext';
import { AgeCategoryType } from '../../../domain/ageCategory';
import { useFeatureToggle } from '../../../hooks/getters/useFeatureToggle';
import { useIsPartiallyCurrentRoute } from '../../../hooks/utils/useIsPartiallyCurrentRoute';
import { LoanFormatType } from '../../../utils/domain/loanFormat';
import { i18nLoanFormatMap, i18nPageTypeMap } from '../../../utils/localizations/i18nextMaps';
import { getActiveLanguage, getAvailableOtherLanguages } from '../../../utils/navigation/languageBrowsing';
import { selectAvailablePages } from '../../../utils/navigation/navSelectors';
import { isEmpty, isPresent } from '../../../utils/objectChecks';
import {
    ACCESS_PATH,
    HOME_DISCOVER_PATH,
    HOME_PATH,
    MY_LOANS_PATH,
    MY_READING_LISTS_PATH
} from '../../../utils/routes/paths';
import { Icon } from '../../Icon';
import { usePageRef } from '../../ProductsPageLink';

type ProductsNavigationProps = {
    readonly loanFormat: LoanFormatType | undefined;
    readonly ageCategory: AgeCategoryType;
    readonly pageType: ProductsPageType;
    readonly pageSuffix?: string;
};

export const ProductsNavigation: FC<ProductsNavigationProps> = ({ loanFormat, ageCategory, pageType, pageSuffix }) => {
    const { t } = useTranslation();
    const libraryContext = useLibraryContext();
    const availablePageTypes = selectAvailablePages(libraryContext.navigation, loanFormat ?? null, ageCategory);

    const activeLanguage = getActiveLanguage(libraryContext.browsingLanguages);
    const isLanguageBrowsingActive = useFeatureToggle('languageBrowsing');

    const navItems = sortBy(availablePageTypes, pageType => PAGE_TYPE_ORDER[pageType]).map(pageType => ({
        pageType,
        label:
            !loanFormat || pageType !== 'all'
                ? t(`header.nav.pages.${i18nPageTypeMap[pageType]}`)
                : t(`all_products.titles.${i18nLoanFormatMap[loanFormat]}`)
    }));

    if (isEmpty(navItems)) return null;

    return (
        <nav className="main" aria-label={t('header.nav.second_line_label')}>
            <div className={classNames({ 'component navigate-back': activeLanguage })}>
                {activeLanguage ? (
                    <>
                        <a
                            className="button-neutral theme-rounded"
                            href={productsPagePath({ loanFormat, ageCategory })}
                        >
                            <Icon icon="arrow-back" />
                        </a>
                        <span>{t(`filters:genre.${activeLanguage.genreId}`, activeLanguage.slug)}</span>
                    </>
                ) : null}
            </div>
            <div>
                {loanFormat ? (
                    <AgeCategoryDropdown
                        loanFormat={loanFormat}
                        selectedAgeCategory={ageCategory}
                        pageType={pageType}
                        pageSuffix={pageSuffix}
                    />
                ) : null}

                <ul className="nav-links">
                    {navItems.map(({ pageType: linkPageType, label }) => (
                        <NavLink
                            key={linkPageType}
                            loanFormat={loanFormat}
                            ageCategory={ageCategory}
                            pageType={linkPageType}
                            languageSlug={activeLanguage?.slug}
                            label={label}
                        />
                    ))}
                </ul>
            </div>
            <div>
                {!activeLanguage && isLanguageBrowsingActive && loanFormat ? (
                    <LanguageDropdown loanFormat={loanFormat} selectedAgeCategory={ageCategory} pageType={pageType} />
                ) : null}
            </div>
        </nav>
    );
};

export const LanguageNavigation: FC<ProductsNavigationProps> = ({ loanFormat, ageCategory, pageType, pageSuffix }) => {
    const { t } = useTranslation();
    const { browsingLanguages } = useLibraryContext();

    const activeLanguage = getActiveLanguage(browsingLanguages);

    const options = uniqBy(
        compact([...getAvailableOtherLanguages(browsingLanguages, loanFormat, ageCategory), activeLanguage ?? null]),
        'genreId'
    );

    return (
        <nav className="main" aria-label={t('header.nav.second_line_label')}>
            <div className="component navigate-back">
                <a className="button-neutral theme-rounded" href={productsPagePath({ loanFormat, ageCategory })}>
                    <Icon icon="arrow-back" />
                </a>
                <span className="label">{t('languageBrowsing.labels.otherLanguages')}</span>
            </div>
            <div>
                {loanFormat ? (
                    <>
                        <AgeCategoryDropdown
                            loanFormat={loanFormat}
                            selectedAgeCategory={ageCategory}
                            pageType={pageType}
                            pageSuffix={pageSuffix}
                        />
                        <ul className="nav-links">
                            {isPresent(options)
                                ? options.map(({ genreId, active, slug }) => (
                                      <li key={genreId} className={classNames({ active })}>
                                          <div className="link-wrapper">
                                              <a
                                                  href={productsPagePath({
                                                      loanFormat,
                                                      languageSlug: slug,
                                                      ageCategory
                                                  })}
                                                  className="libcolor-text-active"
                                              >
                                                  {t(`filters:genre.${genreId}`, slug)}
                                              </a>
                                          </div>
                                      </li>
                                  ))
                                : null}
                        </ul>
                    </>
                ) : null}
            </div>
            <div></div>
        </nav>
    );
};

type NavLinkProps = IProductsPageRef;

const NavLink: FC<NavLinkProps> = ({ loanFormat, ageCategory, pageType, label, languageSlug }) => {
    const pageRef = usePageRef(loanFormat, ageCategory, pageType);
    const path = productsPagePath({ ...pageRef, languageSlug });
    const { isPartiallyCurrent } = useIsPartiallyCurrentRoute(path);

    return (
        <li className={isPartiallyCurrent ? 'active' : ''}>
            <div className="link-wrapper">
                <Link href={path} className="libcolor-text-active">
                    {label}
                </Link>
            </div>
        </li>
    );
};

export const HomeNavigation: FC = () => {
    const { t } = useTranslation();
    const { isCurrent: isDiscoverPage } = useIsPartiallyCurrentRoute(HOME_PATH);
    const { isPartiallyCurrent: isMyLoansPage } = useIsPartiallyCurrentRoute(MY_LOANS_PATH);
    const { isPartiallyCurrent: isMyReadingListsPage } = useIsPartiallyCurrentRoute(MY_READING_LISTS_PATH);
    const { isPartiallyCurrent: isDownloadPage } = useIsPartiallyCurrentRoute(ACCESS_PATH);

    return (
        <nav className="main" aria-label={t('header.nav.second_line_label')}>
            <ul className="nav-links">
                <li className={isDiscoverPage ? 'active' : ''}>
                    <div className="link-wrapper">
                        <Link href={HOME_DISCOVER_PATH} className="libcolor-text-active">
                            {t('header.nav.discover')}
                        </Link>
                    </div>
                </li>
                <li className={isMyLoansPage || isDownloadPage ? 'active' : ''}>
                    <div className="link-wrapper">
                        <Link href={MY_LOANS_PATH} className="libcolor-text-active">
                            {t('header.nav.my_loans')}
                        </Link>
                    </div>
                </li>
                <li className={isMyReadingListsPage ? 'active' : ''}>
                    <div className="link-wrapper">
                        <Link href={MY_READING_LISTS_PATH} className="libcolor-text-active">
                            {t('header.nav.my_reading_lists')}
                        </Link>
                    </div>
                </li>
            </ul>
        </nav>
    );
};
