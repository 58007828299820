import { AgeCategoryType } from '../../../domain/ageCategory';
import { LoanFormatType } from '../../../utils/domain/loanFormat';

/**
 * Slugs for all loan formats. They are intended to represent loan formats within the URL.
 */
export const LoanFormatSlugs: { [key in LoanFormatType]: string } = {
    [LoanFormatType.ebooks]: 'ebooks',
    [LoanFormatType.eaudiobooks]: 'eaudiobooks',
    [LoanFormatType.eMagazineIssues]: 'emagazineissues',
    [LoanFormatType.eMagazines]: 'epress'
};

/**
 * Slugs for all age categories. They are intended to represent age categories within the URL.
 */
export const AgeCategorySlugs: { [key in AgeCategoryType]: string } = {
    [AgeCategoryType.allAges]: '',
    [AgeCategoryType.adult]: 'adult',
    [AgeCategoryType.youngAdult]: 'young-adult',
    [AgeCategoryType.children]: 'children'
};

export const AGE_CATEGORY_ORDER: { [key in AgeCategoryType]: number } = {
    [AgeCategoryType.allAges]: 10,
    [AgeCategoryType.adult]: 20,
    [AgeCategoryType.youngAdult]: 30,
    [AgeCategoryType.children]: 40
};

/**
 * All supported types/slugs of product pages.
 */
export enum ProductsPageType {
    All = 'all',
    AllEMagazines = 'eMagazines',
    AllENews = 'eNews',
    Featured = 'featured',
    Explore = 'explore',
    Genres = 'genres'
}

export const PAGE_TYPE_ORDER: { [P in ProductsPageType]: number } = {
    [ProductsPageType.Featured]: 10,
    [ProductsPageType.AllEMagazines]: 20,
    [ProductsPageType.AllENews]: 30,
    [ProductsPageType.Explore]: 40,
    [ProductsPageType.Genres]: 50,
    [ProductsPageType.All]: 60
};

/**
 * Represents the reference to a specific products page. It is intended to be used as an abstraction for building
 * routes/paths to products pages.
 */
export interface IProductsPageRef {
    /**
     * The loan format of the referenced page, can be omitted in case the page support all loan formats.
     */
    loanFormat?: LoanFormatType;

    /**
     * The language of the referenced page, can be omitted which will then lead to the default language.
     */
    languageSlug?: string;

    /**
     * The age category of the referenced page, can be omitted which will then be interpreted as 'All Ages'.
     */
    ageCategory?: AgeCategoryType;

    /**
     * The type of the referenced page, can be omitted which will cause the library default to be used instead.
     */
    pageType?: ProductsPageType;

    /**
     * An optional suffix that will be appended to URLs.
     */
    pageSuffix?: string;

    /**
     * A human-readable label which references the page for human users. This property is completely optional.
     * Its purpose is to attach a human-readable label to links which are represented as products page references,
     * which is a common use case for structures of this type.
     */
    label?: string;
}

/**
 * Builds a full path to a specific products page, based on a given products page reference.
 */
export function productsPagePath({ loanFormat, languageSlug, ageCategory, pageType }: IProductsPageRef): string {
    const loanFormatSegment = loanFormat ? `/${LoanFormatSlugs[loanFormat]}` : '';

    const languageSegment = languageSlug ? `/language/${languageSlug}` : '';

    const ageCategorySegment =
        ageCategory && ageCategory !== AgeCategoryType.allAges ? `/${AgeCategorySlugs[ageCategory]}` : '';

    const pageTypeSegment = pageType ? `/${slugifyPageType(pageType)}` : '';

    return `${loanFormatSegment}${languageSegment}${ageCategorySegment}${pageTypeSegment}`;
}

function slugifyPageType(pageType: ProductsPageType): string {
    return pageType.toLowerCase();
}
