import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';

import { INavAgeCategoryState, INavCollectionState, INavFormatState, INavigationState } from './parseNavigationState';
import { AGE_CATEGORY_ORDER, PAGE_TYPE_ORDER, ProductsPageType } from '../../components/layout/header/Navigation';
import { LibraryContextValue } from '../../context/LibraryContext';
import { AgeCategoryType } from '../../domain/ageCategory';
import { DEFAULT_COLLECTION_SLUG } from '../constants';
import { LoanFormatType } from '../domain/loanFormat';
import { isEmpty, isPresent } from '../objectChecks';

function getAgeCategoryNav<L extends LoanFormatType, A extends AgeCategoryType>(
    collection: INavCollectionState,
    loanFormat: L,
    ageCategory: A
): INavAgeCategoryState<A> | null {
    const loanFormatNav = collection.loanFormats[loanFormat];

    if (!loanFormatNav) return null;

    const ageCategoryNav = loanFormatNav.ageCategories[ageCategory] as INavAgeCategoryState<A> | undefined;

    return ageCategoryNav || null;
}

function selectLoanFormatNav<L extends LoanFormatType>(
    navigation: INavigationState,
    loanFormat: L
): INavFormatState<L> | null {
    const collection = navigation.collections[DEFAULT_COLLECTION_SLUG];
    return collection.loanFormats[loanFormat] ?? null;
}

function selectAgeCategoryNav<A extends AgeCategoryType>(
    navigation: INavigationState,
    loanFormat: LoanFormatType,
    ageCategory: A
): INavAgeCategoryState<A> | null {
    const collection = navigation.collections[DEFAULT_COLLECTION_SLUG];

    if (isEmpty(collection) || !loanFormat || !ageCategory) return null;

    return getAgeCategoryNav(collection, loanFormat, ageCategory);
}

export function selectDefaultLoanFormat(navigation: INavigationState): LoanFormatType | null {
    const nav = navigation.collections[DEFAULT_COLLECTION_SLUG];

    if (isEmpty(nav)) return null;

    return Object.values(nav.loanFormats).find(({ loanFormat }) => isPresent(loanFormat))?.loanFormat ?? null;
}

export function selectDefaultAgeCategory(
    navigation: INavigationState,
    loanFormat: LoanFormatType | null
): AgeCategoryType {
    if (loanFormat) {
        const loanFormatNav = selectLoanFormatNav(navigation, loanFormat);

        if (loanFormatNav) {
            const ageCategoryNav = Object.values(loanFormatNav.ageCategories).find(isPresent);

            if (ageCategoryNav) return ageCategoryNav.ageCategory;
        }
    }

    return AgeCategoryType.allAges;
}

export function selectDefaultPageType(
    navigation: INavigationState,
    loanFormat: LoanFormatType,
    ageCategory: AgeCategoryType
): ProductsPageType | null {
    const availablePages = selectAvailablePages(navigation, loanFormat, ageCategory);
    const sortedPages = sortBy(availablePages, page => PAGE_TYPE_ORDER[page]);
    return sortedPages[0] ?? ProductsPageType.All;
}

/**
 * A selector which indicates whether a specific loan format is available within a specific collection of the navigation
 * structure.
 */
export function selectLoanFormatAvailable(navigation: INavigationState, loanFormat: LoanFormatType): boolean {
    const nav = selectLoanFormatNav(navigation, loanFormat);
    return isPresent(nav?.ageCategories);
}

/**
 * A selector which indicates whether a specific age category is available within a collection/loan-format part of the
 * navigation structure.
 */
export function selectAgeCategoryAvailable(
    navigation: INavigationState,
    loanFormat: LoanFormatType,
    ageCategory: AgeCategoryType
): boolean {
    const nav = selectAgeCategoryNav(navigation, loanFormat, ageCategory);

    if (!nav) return false;

    return Object.values(ProductsPageType)
        .map(type => nav[type])
        .some(({ visible }) => visible);
}

/**
 * A selector which indicates whether a specific page type is available within a collection/loan-format/age-category
 * part of the navigation structure.
 */
export function selectPageAvailable(
    navigation: INavigationState,
    loanFormat: LoanFormatType | null | undefined,
    ageCategory: AgeCategoryType | null | undefined,
    page: ProductsPageType = ProductsPageType.All
): boolean {
    // special handling for the Discover Page
    if (page === ProductsPageType.Featured && !loanFormat) return true;

    // except for the Discover Page, loan format and age category are mandatory
    if (loanFormat && ageCategory) {
        const navState = selectAgeCategoryNav(navigation, loanFormat, ageCategory);
        if (navState) return navState[page].visible;
    }

    return false;
}

/**
 * A selector which resolves the available loan formats for a specific collection.
 */
export function selectAvailableLoanFormats(libraryContext: LibraryContextValue): ReadonlyArray<LoanFormatType> {
    const nav = libraryContext.navigation.collections[DEFAULT_COLLECTION_SLUG];

    if (isEmpty(nav)) return [];

    return compact(Object.values(nav.loanFormats).map(navFormatState => navFormatState.loanFormat));
}

/**
 * A selector which resolves the available loan formats for a specific collection & loan-format.
 */
export function selectAvailableAgeCategories(
    navigation: INavigationState,
    loanFormat: LoanFormatType
): ReadonlyArray<AgeCategoryType> {
    const loanFormatNav = selectLoanFormatNav(navigation, loanFormat);

    if (!loanFormatNav) return [];

    return sortBy(
        Object.values(AgeCategoryType).filter(ageCategory =>
            selectAgeCategoryAvailable(navigation, loanFormat, ageCategory)
        ),
        ageCategory => AGE_CATEGORY_ORDER[ageCategory]
    );
}

/**
 * A selector which resolves the available pages within a specific collection/loan-format/age-category part of the
 * navigation structure.
 */
export function selectAvailablePages(
    navigation: INavigationState,
    loanFormat: LoanFormatType | null,
    ageCategory: AgeCategoryType
): ReadonlyArray<ProductsPageType> {
    return Object.values(ProductsPageType).filter(pageType =>
        selectPageAvailable(navigation, loanFormat, ageCategory, pageType)
    );
}
